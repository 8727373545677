
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import axios from "axios";
@Component
export default class Home extends Vue {
  headers: Array<any> = [
    {
      text: "",
      value: "eventResult",
      sortable: false,
    },
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата",
      value: "date",
      sortable: false,
      width: "200px",
    },
    {
      text: "Менеджер",
      value: "clientManager",
      sortable: false,
      width: "200px",
    },
    // {
    //   text: "Плеер",
    //   value: "player",
    //   align: "center",
    //   sortable: false,
    // },
    {
      text: "Источник",
      value: "source",
      align: "end",
      sortable: false,
      width: "150px",
    },
    {
      text: "Телефон",
      value: "callerPhone",
      align: "end",
      sortable: false,
      width: "100px",
    },
    {
      text: "Клиент",
      value: "client",
      sortable: false,
    },
    /*
    {
      text: "Входящий",
      value: "destPhone",
      align: "end",
      sortable: false,
    },
    */
    {
      text: "Длительность",
      value: "duration",
      sortable: false,
      width: "50px",
    },

    {
      text: "Стенограмма",
      value: "transcribtion",
      sortable: false,
      width: "50px",
    },
    /*
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },*/
  ];
  audioTextDialogVisible: boolean = false;
  currentItem: any = null;
  currentTime: number = 0;
  importDialog = false;
  dateStart = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  dateStartMenu = false;
  dateEnd = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  dateEndMenu = false;

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 50,
      orderFieldName: "Date",
      orderFieldDirection: "DESC",
    },
    className: "ClientPhoneCall",
  });

  importPhoneCalls() {
    let _result = axios.get(
      `/manage/calltouch/import/?dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`
    );

    this.importDialog = false;
    this.dataSource.get();
  }

  async start(item) {
    let url = `manage/clientphonecall/recognize`;
    const { data } = await axios.post(url, [item.id]);
    for (item of data) {
      let index = this.dataSource.items.findIndex((a) => a.id == item.id);
      if (index >= 0) {
        Vue.set(this.dataSource.items, index, item);
      }
    }
  }
  async get(item) {
    let url = `manage/clientphonecall/ttsdata`;
    const { data } = await axios.post(url, [item.id]);
    for (item of data) {
      let index = this.dataSource.items.findIndex((a) => a.id == item.id);
      if (index >= 0) {
        Vue.set(this.dataSource.items, index, item);
      }
    }
  }
  show(item) {
    this.currentItem = item;
    for (let chunk of this.currentItem.transcribtion.response.chunks) {
      chunk.startTime = this.parseAudioPosition(
        chunk.alternatives[0]?.words[0]?.startTime
      );
    }
    this.currentItem.transcribtion.response.chunks =
      this.currentItem.transcribtion.response.chunks.sort(function (a, b) {
        return a.startTime - b.startTime;
      });
    this.currentTime = 0;
    this.audioTextDialogVisible = true;
  }

  seekAudio(startTime, endTime) {
    (this.$refs.audio as any).currentTime = this.parseAudioPosition(startTime);
  }
  timeUpdate(event) {
    this.currentTime = event.srcElement.currentTime;
  }

  parseAudioPosition(position) {
    return position ? parseFloat(position.replace("s", "")) : null;
  }
}
